import React from 'react'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import { LoginModal } from '@/modals'
import './layout.css'

function MainLayout({ children, onMain, location }) {
  return (
    <div className='container'>
      <Header onMain={onMain} location={location} />
      {children}
      <Footer />
      <LoginModal />
    </div>
  )
}

export default MainLayout
